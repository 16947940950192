<template>
    <div>
        <div class="coonperation" style="width: 70%; margin-left: 15%; display:block" v-show="coonperationshow">
        <!-- 商业发展与合作 -->
            <div style="width: 100%; text-align: center; padding: 1em 0em 2em 0em; font-size: 1.5em;">
                以患者为中心的商业合作
            </div>
            <table width="100%" cellpadding="0" cellspacing="0" style=" color: #FFFFFF; ">
                <tbody>
                    <tr>
                        <td style="width: 50%;   ">
                                <img width="100%" style="display: block; " src="image/about013.jpg">
                        </td>
                        <td style="width: 50%; background-color: #145a75f6 ; ">
                            <div style="padding: 0px 7%; ">
                                <span style="text-align: left; font-size: 1.8vw; display:block; padding: 2% 1% 1% 0%;">商业发展与合作</span>
                                
                                <p style="font-size: 1.2vw; margin:0.01vw 0px;">
                                    创新技术，可靠产品 , 期待与您共同参与医疗未来前景, 让更多患者受益于菌群移植
                                </p>
                                <div style="display:flex; ">
                                    <p style="font-size: 1.2vw; padding-left:11%; margin:10px 0px;">公众号</p>
                                    <p style="font-size: 1.2vw; padding-left:46%; margin:10px 0px;">微信客服</p>
                                </div>
                                <div style="display:flex; ">
                                    <img width="35%" style="display:block; " src="image/subscriber.jpg">
                                    <img width="35%" style="display:block; padding-left:25%" src="image/Service.jpg">
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="mobcoonperation"  v-show="mobcoonperationshow">
            <!-- 商业发展与合作 -->
            <div style="text-align: center; padding: 0.5em; font-size: 36px; background-color: #145a75f6 ; color: #FFFFFF; text-align: center; ">
                以患者为中心的商业合作 
            </div>
            <img width="100%" src="image/about013.jpg">

            <div style="padding: 1em; background-color: #00857d; margin-top: -1em;">
                <span style="text-align: left; font-size: 36px;">商业发展与合作</span>
                <div class="fengeline1em"></div>
                创新技术，可靠产品 , 期待与您共同参与医疗未来前景, 让更多患者受益于菌群移植
                <div style="display:flex; ">
                    <p style="padding-left:1em; margin:10px 0px;">公众号</p>
                    <p style="padding-left:5.3em; margin:10px 0px;">微信客服</p>
                </div>
                <div style="display:flex; ">
                    <img width="40%" style="display:block; " src="image/subscriber.jpg">
                    <img width="40%" style="display:block; padding-left:2.5em;" src="image/Service.jpg">
                </div>
            </div>
        </div>

        <div class="fengeline1em"></div>
        <div class="Footer_footer__cLTss" >
            <div>Copyright ©2023 君常怡生物科技有限公司</div>
            <div>
                <img src="image/beian.png" alt="" style="margin-right: 10px;">
                <a href="https://beian.miit.gov.cn/">粤ICP备2021063149号</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        }
    },
    data(){
        return {
            coonperationshow: false ,
            mobcoonperationshow: false ,
        }
    },
    mounted: function(){
        if(this.isMobile) {
            this.mobcoonperationshow = true ;
        }else {
            this.coonperationshow = true ; 
        }
    },
}
</script>

<style>
    
    .Footer_footer__cLTss{
        margin: 1em 0em 0em 0em;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        --antd-wave-shadow-color: #1890ff;
        --scroll-bar: 0;
        -webkit-font-smoothing: antialiased;
        font-feature-settings: "tnum","tnum";
        color: rgba(0,0,0,.85);
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
        font-size: 16px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        box-sizing: border-box;
        align-items: center;
        border-top: 1px solid #eee;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
</style>