import Vue from 'vue'
import Router from 'vue-router'

import index from '../views/index/index.vue';
import news from '../views/news/news.vue';
import product from '../views/product/product.vue';
import content from '../views/content/content.vue';
import contact from '../views/contact/contact.vue';

Vue.use(Router);

const routers = [{
        path: '/',
        name: '/',
        component: index,
        meta: { requiresAuth: false }
    },
    {
        path: '/index',
        name: 'index',
        component: index,
        meta: { requiresAuth: false },
    },
    {
        path: '/news',
        name: 'news',
        component: news,
        meta: { requiresAuth: false },
    },
    {
        path: '/content',
        name: 'content',
        component: content,
        meta: { requiresAuth: false },
    },
    {
        path: '/product',
        name: 'product',
        component: product,
        meta: { requiresAuth: false },
    },
    {
        path: '/contact',
        name: 'contact',
        component: contact,
        meta: { requiresAuth: false },
    },
];
const router = new Router({
    mode: 'history',
    routers,
});

export default router;