<template>
  <div>
        <!-- pc -->
        <div class="main" v-show="mainshow">
            <!-- banner -->
            <table width="100%" style="padding-left: 5%; " cellpadding="0" cellspacing="40">
                <tbody>
                    <tr>
                        <td style="width: 50%;">
                            <img width="100%"  style="vertical-align: middle; opacity: 1;" src="image/index.png">
                        </td>
                        <td style="padding-left: 10%;">
                            <font style="font-size: 48px; font-weight:bold; ">安复每天</font>
                            <div class="fengeline2em"></div>
                            <font style="font-size: 24px;">留住现在 安复每天</font>
                            <div class="fengeline2em"></div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div style="width: 80%; margin: 0px 10%; text-align: center; line-height: 2em;">
                <div class="fengeline2em"></div>
                <div class="fengeline2em"></div>
                <font style="font-size: 36px; font-weight:bold;">安复每天是一家专业的菌群移植 FMT 的方案提供商</font>
                <div class="fengeline2em"></div>
                <font>
                    公司尖端科技实现从“粪便移植”到“有菌无粪”的蜕变，让更多人受益于菌群移植，有望在不久的未来菌群移植将实现部分药品替代。
                    <br>
                    <br> 安复每天主体为深圳市君常怡生物科技公司,公司主营业务“菌群移植”是一项通过提纯粪便
                    <br>获得健康菌群移植至患者体内，实现健康恢复的医疗手段。
                    <br>
                    <br> 安复每天创始团队最早在德国创立，后续在国内投入大量资金，开发的新一代FMT创新。
                    <br> 公司开发FMT胶囊适用于医疗、健康管理，方向包括肿瘤治疗，消化、免疫、代谢疾病、精神疾病治疗。
                    <br>
                    <br> 公司拥有医学发明专利多项，并采用国际领先制备方案，在菌群移植领域具有绝对技术领先优势。
                    <br> 公司2021年开始与国内外多家知名医院、机构开展合作，为市场提供优质FMT产品。
                </font>
                <div class="fengeline2em"></div>
                <div class="fengeline2em"></div>
                <div class="fengeline2em"></div>
            </div>
        </div>


        <!-- moblie -->
        <div class="mobmain" v-show="mobmainshow">
            <!-- banner -->
            <img width="100%" src="image/index.png">
            <font style="font-size: 40px; padding-left: 10px; display:none;">关于安复每天</font>
            <div class="fengeline1em"></div>
            <font style="font-size: 20px; padding-left: 10px; display:none;">留住现在 安复每天</font>
            
            <div style=" text-align: center; padding: 0px 2px;">
                <font style="font-size: 28px; font-weight:bold; ">安复每天是一家专业的菌群移植 FMT 的方案提供商</font>
                <br>
                <div style=" text-align: left;">
                <font style="font-size: 22px; ">
                    
                    <br> 安复每天主体为深圳市君常怡生物科技公司，最早在德国创立，后续在国内投入大量资金，拥有医学发明专利多项，采用国际领先制备方案，在菌群移植领域具有绝对技术领先优势。 
                    <br>
                    <br> 公司尖端科技实现从“粪便移植”到“有菌无粪”的蜕变，让更多人受益于菌群移植，有望在不久的未来菌群移植将实现部分药品替代。
                </font>
                </div>
                <div class="fengeline1em"></div>
                <div class="fengeline1em"></div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        }
    },
    data(){
        return {
            mainshow : false , 
            mobmainshow: false ,
        }
    },
    mounted: function(){
        if(this.isMobile) {
            this.mobmainshow = true ;
        }else {
            this.mainshow = true ; 
        }
    },
}
</script>

<style>

</style>