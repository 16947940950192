<template>
    <div>
        <!-- pc -->
        <div class="main" style="width: 90%; margin-left: 5%;" v-show="mainshow">
            <!-- banner -->
            <table width="100%" cellpadding="0" cellspacing="40">
                <tbody>
                    <tr>
                        <td style="width: 50%;">
                            <img width="120%"  style="vertical-align: middle;opacity: 1;" src="image/newslogo.png">
                        </td>
                        <td style="padding-left: 15%;">
                            <font style="font-size: 48px; font-weight:bold;">安复每天</font>
                            <div class="fengeline2em"></div>
                            <font style="font-size: 30px;">资讯快送</font>
                            <div class="fengeline2em"></div>
                        </td>
                    </tr>
                </tbody>
            </table>

        
            <div class="fengeline2em"></div>
            <div class="fengeline2em"></div>
            <div class="fengeline2em"></div>

            <!-- 新闻速递 -->
            <div id="mediamainbody" style="width: 70%; margin-left: 12%;">
                <div style="border-bottom: 1px solid #EEEEEE;">
                    <div style="font-size: 36px; font-weight:bold;">资讯快送</div>
                    <div class="fengeline1em"></div>
                    <div>
                        FMT技术不仅在国际具有重要医疗影响力，而且有望在不久的未来FMT将实现部分药品替代。
                    </div>
                    <div class="fengeline2em"></div>
                    
                </div>

                <div class="fengeline2em"></div>
                <div class="main">
                    <table cellpadding="0" cellspacing="0">
                        <tbody><tr>
                            
                            <td class="producttypeitemtd2" @click="ChooseYear(0)"   valign="top" year="all">
                                <div class="producttypeitemdiv2" v-bind:class="[currentYear == 0 ? chosedcls : '']">全部</div>
                                <div style="height: 4px;"></div>
                                <i class="down"  v-bind:class="[currentYear == 0 ? '' : 'hlshide']"></i>
                            </td>
                            <td style="width: 1.5em;"></td>
                            <td class="producttypeitemtd2" @click="ChooseYear(2022)"  valign="top" year="2022">
                                <div class="producttypeitemdiv2" v-bind:class="[currentYear == 2022 ? chosedcls : '']">2022</div>
                                <div style="height: 4px;"></div>
                                <i class="down"  v-bind:class="[currentYear == 2022 ? '' : 'hlshide']"></i>
                            </td>
                            <td style="width: 1.5em;"></td>
                            <td class="producttypeitemtd2" @click="ChooseYear(2021)" valign="top" year="2021">
                                <div class="producttypeitemdiv2" v-bind:class="[currentYear == 2021 ? chosedcls : '']">2021</div>
                                <div style="height: 4px;"></div>
                                <i class="down"  v-bind:class="[currentYear == 2021 ? '' : 'hlshide']"></i>
                            </td>
                            <td style="width: 1.5em;"></td>
                            <td class="producttypeitemtd2" @click="ChooseYear(2020)"  valign="top" year="2020">
                                <div class="producttypeitemdiv2">2020</div>
                                <div style="height: 4px;"></div>
                                <i class="down"  v-bind:class="[currentYear == 2020 ? '' : 'hlshide']"></i>
                            </td>
                            <td style="width: 1.5em;"></td>
                            <td class="producttypeitemtd2" @click="ChooseYear(2019)"  valign="top" year="2019">
                                <div class="producttypeitemdiv2">2019</div>
                                <div style="height: 4px;"></div>
                                <i class="down"  v-bind:class="[currentYear == 2019 ? '' : 'hlshide']"></i>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
		        <div class="fengeline1em" style="border-bottom: 1px solid #EEEEEE;"></div>
                <table class="newsitem" cellpadding="0" cellspacing="0" v-if="currentData.length != 0">
                    <tbody v-for="item of currentData" :key="item.id" >
                        <tr :year=currentYear @click="goNews(item.id)" style="display: table-row;">
                            <td class="newsitemdate">{{item.createTime.split(" ")[0]}}</td>
                            <td class="newsitemtitle">{{item.title}}</td>
                        </tr>
                    </tbody>
                </table>
                <ul class="pagination">
                    <li><a style="cursor: pointer;" @click="prePage()" v-bind:style="[currentIndex == 1 ? disCurstyle : '']" >«</a></li>
                    <li v-for="index in indexAll" @click="goPage(index)" :key="index" >
                        <a style="cursor: pointer;" v-bind:class="[currentIndex == index ? 'active' : '']">{{index}}</a>
                    </li>
                    <li><a style="cursor: pointer;" @click="nextPage()" :style="[currentIndex == indexAll ? disCurstyle : '']" >»</a></li>
                </ul>
            </div>
        </div>
        <div class="fengeline1em"></div>
        <div class="fengeline1em"></div>

    
        <!-- moblie -->
        <div class="mobmain" v-show="mobmainshow">
            <!-- banner -->
            <img width="100%" src="image/newslogo.png">
            <font style="font-size: 40px; padding-left: 10px; display:none;">安复每天</font>
            <div class="fengeline1em"></div>
            <font style="font-size: 20px; padding-left: 10px; display:none;">资讯快送</font>
            
            <div style=" text-align: center; padding: 0px 2px;">
                <font style="font-size: 28px; font-weight:bold;  ">安复每天是一家专业的菌群移植 FMT 的方案提供商</font>
                <br>
                <font style="font-size: 22px; ">
                    
                    <br> 安复每天主体为深圳市君常怡生物科技公司，最早在德国创立，后续在国内投入大量资金，拥有医学发明专利多项，采用国际领先制备方案，在菌群移植领域具有绝对技术领先优势。 
                    <br>
                </font>
            </div>
            <div class="fengeline1em"></div>
            <div class="fengeline1em"></div>
            <div style="border-bottom: 1px solid #EEEEEE;">
                <div style="font-size: 36px; margin-bottom:2%; font-weight:bold;">资讯快送</div>
                <div>
                    FMT技术不仅在国际具有重要医疗影响力，而且有望在不久的未来FMT将实现部分药品替代。
                </div>
                <div class="fengeline2em"></div>
                
            </div>
            
            <table cellpadding="0" cellspacing="0">
                <tbody><tr>
                    <td class="producttypeitemtd2" @click="ChooseYear(0)"  valign="top" year="all">
                        <div class="producttypeitemdiv2" v-bind:class="[currentYear == 0 ? chosedcls : '']" >全部</div>
                        <div style="height: 4px;"></div>
                        <i class="down" v-bind:class="[currentYear == 0 ? '' : 'hlshide']"></i>
                    </td>
                    <td style="width: 1.5em;"></td>
                    <td class="producttypeitemtd2" @click="ChooseYear(2022)"  valign="top" year="2022">
                        <div class="producttypeitemdiv2" v-bind:class="[currentYear == 2022 ? chosedcls : '']" >2022</div>
                        <div style="height: 4px;"></div>
                        <i class="down" v-bind:class="[currentYear == 2022 ? '' : 'hlshide']"></i>
                    </td>
                    <td style="width: 1.5em;"></td>
                    <td class="producttypeitemtd2" @click="ChooseYear(2021)"  valign="top" year="2021">
                        <div class="producttypeitemdiv2" v-bind:class="[currentYear == 2021 ? chosedcls : '']" >2021</div>
                        <div style="height: 4px;"></div>
                        <i class="down" v-bind:class="[currentYear == 2021 ? '' : 'hlshide']"></i>
                    </td>
                    </tr>
                    <tr>
                    <td class="producttypeitemtd2" @click="ChooseYear(2020)"  valign="top" year="2020">
                        <div class="producttypeitemdiv2" v-bind:class="[currentYear == 2020 ? chosedcls : '']" >2020</div>
                        <div style="height: 4px;"></div>
                        <i class="down" v-bind:class="[currentYear == 2020 ? '' : 'hlshide']"></i>
                    </td>
                    <td style="width: 1.5em;"></td>
                    <td class="producttypeitemtd2" @click="ChooseYear(2019)"  valign="top" year="2019">
                        <div class="producttypeitemdiv2" v-bind:class="[currentYear == 2019 ? chosedcls : '']" >2019</div>
                        <div style="height: 4px;"></div>
                        <i class="down" v-bind:class="[currentYear == 2019 ? '' : 'hlshide']"></i>
                    </td>
                    <td style="width: 1.5em;"></td>
                </tr>
                </tbody>
            </table>
            <div class="fengeline1em" style="border-bottom: 1px solid #EEEEEE;"></div>
            <table class="newsitem" cellpadding="0" cellspacing="0" v-if="currentData.length != 0">
                <tbody v-for="item of currentData" :key="item.id" >
                    <tr :year=currentYear @click="goNews(item.id)" style="display: table-row;">
                        <td class="mpbnewsitemdate">{{item.createTime.split(" ")[0]}}</td>
                        <td class="newsitemtitle">{{item.title}}</td>
                    </tr>
                </tbody>
            </table>
            <ul class="pagination">
                <li><a style="cursor: pointer;" @click="prePage()" v-bind:style="[currentIndex == 1 ? disCurstyle : '']" >«</a></li>
                <li v-for="index in indexAll" @click="goPage(index)" :key="index" >
                    <a style="cursor: pointer;" v-bind:class="[currentIndex == index ? 'active' : '']">{{index}}</a>
                </li>
                <li><a style="cursor: pointer;" @click="nextPage()" :style="[currentIndex == indexAll ? disCurstyle : '']" >»</a></li>
            </ul>
            <div class="fengeline2em"></div>

        </div>
  </div>
</template>



<script>
export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        },
        url :{
            type : String,
            default: "" ,
        }
    },
    data(){
        return {
            chosedcls : 'chosedcls',
            hlshide : 'hlshide',
            currentYear : 0 , 
            mainshow : false , 
            mobmainshow: false ,
            indexAll : 5,
            currentIndex : 1 , 
            disCurstyle :{
                cursor: 'not-allowed',
            },
            dataProject : [] ,
            currentData : [] , 
        }
    },
    methods : {
        ChooseYear(index){
            this.currentYear = index ; 
            this.getResponse(this.currentYear) ; 
            this.currentIndex = 1 ; 

        },
        prePage(){
            if(--this.currentIndex <= 0) {
                this.currentIndex = 1 ; 
            }
            this.currentData = this.dataProject.slice((this.currentIndex - 1) * 5 , this.currentIndex * 5) ; 
           // console.log(this.currentIndex) ; 
        },
        nextPage(){
            if(++this.currentIndex > this.indexAll){
                this.currentIndex = this.indexAll ; 
            }
            this.currentData = this.dataProject.slice((this.currentIndex - 1) * 5 , this.currentIndex * 5) ; 
           // console.log(this.currentIndex) ; 
        },
        goPage(index){
            this.currentIndex = index ;
            this.currentData = this.dataProject.slice((this.currentIndex - 1) * 5 , this.currentIndex * 5) ; 
        },
        goNews(id){
            this.$router.push({
                path: '/content',
                query : {
                    id : id ,
                }
            });
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        getResponse(year){
            let Mydata = new FormData() ; 
            if(year != 0)
                Mydata.append("searchValue" , year) ; 
            var myUrl = this.url + "/api/news/list"; 
            axios (
                {
                    method : "post" , 
                    url : myUrl,
                    data : Mydata ,
                }
            ).then(
                reponse=>{
                    this.dataProject = reponse.data.data.rows  ; 
                    var sum = reponse.data.data.total ;
                    this.indexAll = Math.ceil(sum / 5) ;
                    if(this.indexAll == 0) this.indexAll = 1; // 控制页码总数
                    if(sum < 5){
                        this.currentData = this.dataProject.slice(0 , sum) ; 
                    }else {
                        this.currentData = this.dataProject.slice(0 , 5) ; 
                    }
                }
            ); 
        },
    },
    created:function(){
        this.getResponse(0) ;
    },  
    mounted: function(){
         
        if(this.isMobile) {
            this.mobmainshow = true ;
        }else {
            this.mainshow = true ; 
        }
    },
}
</script>

<style>
	sup{font-size: 0.5em;}
	.up {
	      width: 0;
	      height: 0;
	      border-left: 5px solid transparent;
	      border-right: 5px solid transparent;
	      border-bottom: 10px solid #145a75f6;
	}
	.down {
	      width: 0;
	      height: 0;
	      border-left: 10px solid transparent;
	      border-right: 10px solid transparent;
	      border-top: 10px solid #145a75f6;
	}
	
	.producttypeitemtd,.producttypeitemtd2{width: 5em; font-family: 'SourceHanSansCNRegular'; text-align: center;}
	.producttypeitemdiv,.producttypeitemdiv2{width: 100%; background-color: #FFFFFF; color: #333333; border: 1px solid #145a75f6; text-align: center; padding: 0.5em 0em; cursor: pointer;}
	.producttypeitemtd .chosedcls,.producttypeitemtd2 .chosedcls{ background-color: #145a75f6; color: #FFFFFF; opacity: 1;}
	.producttypeitemtd .hlshide,.producttypeitemtd2 .hlshide{opacity: 0;}
	.downloadshuomingshu{margin-top: 1em;}
	.downloadshuomingshu a{color: #145a75f6;}
	.downloadshuomingshu img{ vertical-align: middle; width: 1.5em; padding-right: 0.2em;}
	
	.newsitem{width: 100%;}
	.newsitem tr{ display: none;}
	.newsitem td{cursor: pointer;}
	.newsitem .newsitemdate{ border-bottom: 1px solid #EEEEEE; padding: 1em 0em; font-size: 32px; font-family: 'SourceHanSansCNRegular'; width: 8em; color: #145a75f6;}
	.newsitem .mpbnewsitemdate{ border-bottom: 1px solid #EEEEEE; padding: 1em 0em; font-size: 28px; font-family: 'SourceHanSansCNRegular'; width:6em; color: #145a75f6;}
	.newsitem .newsitemtitle{ border-bottom: 1px solid #EEEEEE; padding: 1em 0em;}
        
    ul.pagination {
        display: inline-block;
        padding: 0;
        margin: 0;
    }

    ul.pagination li {display: inline;}

    ul.pagination li a {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color .3s;
    }

    ul.pagination li a.active {
        background-color: #145a75f6;
        color: white;
    }

    ul.pagination li a:hover:not(.active) {background-color: #ddd;}
</style>