<template>
    <div>  
        <div class="researchshow" style="width: 70%; margin: 0px 15%; text-align: center; line-height: 2em;" v-show="researchshow">
            <div style="width: 100%; text-align: left;">
                <span style="font-size: 36px; font-weight:bold;">我们的研究领域</span>
                <div class="fengeline1em"></div>
                <font style="font-size: 22px;">
                    我们专注于科研创新、探索、开发 <br> 致力于让更多人受益于菌群移植
                </font>
            </div>
            <div class="fengeline2em"></div>
            <div class="fengeline2em"></div>
            <table width="90%" cellpadding="0" cellspacing="0">
                <tbody>
                    <tr>
                        <td style="width: 10%;"> </td>
                        <td style="width: 30%;">
                            <img style="width: 100%; display: block; opacity: 1;" src="image/development001.png">
                            <div style="padding: 1em 0em 3em 0em; font-weight:bold;">肠道疾病</div>
                        </td>
                         
                        <td style="width: 30%;"> </td>
                        <td style="width: 30%;">
                            <img style="width: 100%; display: block; opacity: 1;" src="image/development003.png">
                            <div style="padding: 1em 0em 3em 0em; font-weight:bold;">肥胖症</div>
                        </td>
                        
                    </tr>
                    <tr>
                        <td style="width: 10%;"> </td>
                        <td style="width: 30%;">
                            <img style="width: 100%; display: block; opacity: 1;" src="image/development007.png">
                            <div style="padding: 1em 0em 3em 0em; font-weight:bold;">精神疾病</div>
                        </td>
                        <td style="width: 30%;"> </td>
                        <td style="width: 30%;">
                            <img style="width: 100%; display: block; opacity: 1;" src="image/development005.png">
                            <div style="padding: 1em 0em 3em 0em; font-weight:bold;">心血管代谢疾病</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="mobresearchshow" style="width: 100%; margin: 10px 1px 0 1px; text-align: center; line-height: 2em;" v-show="mobresearchshow">
            <div style="width: 100%; text-align: left;">
                <span style="font-size: 28px; font-weight:bold;">我们的研究领域</span>
                <div class="fengeline1em" style="margin-bottom:-10px ;"></div>
                <font style="font-size: 22px;">
                    我们专注于科研创新、探索、开发 
                    <br>
                    致力于让更多人受益于菌群移植
                </font>
            </div>
            <div class="fengeline2em" style="margin-bottom:-10px ;"></div>
            <table width="90%" cellpadding="0" cellspacing="0">
                <tbody>
                    <tr>
                        <td style="width: 4%;"> </td>
                        <td style="width: 30%;">
                            <img style="width: 100%; display: block; opacity: 1;" src="image/development001.png">
                            <div style="padding: 1em 0em 3em 0em; font-weight:bold;">肠道疾病</div>
                        </td>
                         
                        <td style="width: 10%;"> </td>
                        <td style="width: 30%;">
                            <img style="width: 100%; display: block; opacity: 1;" src="image/development003.png">
                            <div style="padding: 1em 0em 3em 0em; font-weight:bold;">肥胖症</div>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 4%;"> </td>
                        <td style="width: 30%;">
                            <img style="width: 100%; display: block; opacity: 1;" src="image/development007.png">
                            <div style="padding: 1em 0em 3em 0em; font-weight:bold;">精神疾病</div>
                        </td>
                        <td style="width: 10%;"> </td>
                        <td style="width: 30%;">
                            <img style="width: 100%; display: block; opacity: 1;" src="image/development005.png">
                            <div style="padding: 1em 0em 3em 0em; font-weight:bold;">心血管疾病</div>
                        </td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        
    </div>

</template>

<script>
export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        }
    },
    data(){
        return {
            researchshow : false , 
            mobresearchshow: false ,
        }
    },
    mounted: function(){
        if(this.isMobile) {
            this.mobresearchshow = true ;
        }else {
            this.researchshow = true ; 
        }
    },
}
</script>

<style>

</style>