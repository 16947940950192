<template>
  <div>
        <div class="Content" v-show="Content" v-if="dataProject.length != 0">
            <img :src="dataProject.topPic" style="width: 50%; height: 50%; display: block; margin: 2% 25%; ">
            <div style="margin:0 8%;" v-html="dataProject.content">
                {{dataProject.content}}
            </div>    
        </div>
        <div class="mobContent" v-show="mobContent" v-if="dataProject.length != 0">
            <img :src="dataProject.topPic" style="width: 100%; height: 50%; display: block; margin: 20% 0%; ">
            <div style="margin:0 2%;" v-html="dataProject.content">
                {{dataProject.content}}
            </div> 
        </div>
  </div>
</template>

<script>
export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        } ,
        id : {
            type : String , 
            default: '' , 
        },
        url :{
            type : String,
            default: "" ,
        }
    } ,
    data(){
        return {
            mobContent : false , 
            Content : false , 
            dataProject : [] , 
        }
    } , 
    created:function() {
        let Mydata = new FormData() ; 
        Mydata.append('id' , this.id) ; 
        var myUrl = this.url + "/api/news/selectById";
        axios (
            {
                method : "post" , 
                url : myUrl,
                data : Mydata ,
            }
        ).then(
            dataProject=>{
                this.dataProject = dataProject.data.data  ; 
                if(this.isMobile) {
                    var content = this.dataProject.content ; 
                    this.dataProject.content = content.replace(/<img /g ,
                                '<img style=\"width: 100%; height: 50%; display: block; \"') ;
                }
           }
        ) ;
         
    } ,
    mounted: function(){
        if(this.isMobile) {
            this.mobContent = true ;
        }else {
            this.Content = true ; 
        }
    },
}
</script>

<style>

</style>