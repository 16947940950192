<template>
    <div >
        <!--pc-->
        <div class="product" v-show="productshow">
            <img width="100%" style="opacity: 1;" src="image/about007.jpg">
            <div style="width: 50%; position: absolute; z-index: 9; margin-top: -11em; margin-left: 25%; padding-top:1em; height: 6em; background-color: #145a75f6 ; color: #FFFFFF; text-align: center;">
                <span style="font-size: 45px;">安复每天   值得信赖</span>
                <br>
                <span style="font-size: 22px;">选择 安复每天 4 大理由</span>
                <div class="fengeline1em"></div>
            </div>
            <div class="fengeline2em"></div>
            <div style="width: 80%; margin: 0 10%">
                <table width="100%" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td style="width: 45%;" valign="top">
                                <img width="500px" height="450px" style="opacity: 1;" src="image/Choose1.jpg">
                                <div style="width: 100%;">
                                    <div class="fengeline2em"></div>
                                    <b style="font-size: 24px;">洁净胶囊</b>
                                    <div class="fengeline1em"></div>
                                    <span>
                                    行业首创洁净胶囊 FMT3.0 , 单疗程胶囊数量 14 粒，机构、用户使用更简易
                                    </span>
                                    <div class="fengeline1em"></div>
                                    <div class="fengeline2em"></div>
                                </div>
                            </td>
                            <td style="width: 8%;"> </td>
                            <td style="width: 44%;" valign="top">
                                <img width="500px" height="450px" style="opacity: 1; " src="image/Choose2.jpg">
                                <div style="width: 100%;">
                                    <div class="fengeline2em"></div>
                                    <b style="font-size: 24px;">强技术迭代</b>
                                    <div class="fengeline1em"></div>
                                    <span>
                                        技术储备充足 , 不断升级 , FMT3.5 筛菌技术+基因工程 , 保障合作产品技术领先优势
                                    </span>
                                    <div class="fengeline1em"></div>
                                    <div class="fengeline2em"></div>
                                </div>
                            </td>
                        </tr>
                        
                        <tr>
                            <td valign="top">
                                <img width="450px" height="450px" style="opacity: 1;"  src="image/Choose3.jpg">
                                <div style="width: 100%;">
                                    <div class="fengeline2em"></div>
                                    <b style="font-size: 24px;">全国产品供应商</b>
                                    <div class="fengeline1em"></div>
                                    <span>
                                        为全国超过 50 家机构供应，严格控制质量，稳定产品，杜绝盲目扩张造成质量风险
                                    </span>
                                    <div class="fengeline1em"></div>
                                    <div class="fengeline2em"></div>
                                </div>
                            </td>
                            <td> </td> 
                            <td valign="top">
                                <img width="500px" height="450px" style="opacity: 1;"  src="image/Choose4.jpg">
                                <div style="width: 100%;">
                                    <div class="fengeline2em"></div>
                                    <b style="font-size: 24px;">国家重点临床质量保证</b>
                                    <div class="fengeline1em"></div>
                                    <span>
                                        包括武汉协和医院等，在北京、武汉、昆明、长沙、西安多家国家重点医院临床科研合作，
                                        经过严格医疗审核认证
                                    </span>
                                    <div class="fengeline1em"></div>
                                    <a href="" target="_blank" class="morebf" style="margin-left: 0.2em;">更多</a>
                                    <div class="fengeline2em"></div>
                                    
                                </div>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
        <!--mob-->
        <div class="mobproduct" style="margin: 0px -10px" v-show="mobproductshow">
        <!-- 公司产品 -->
            <img width="100%" src="image/about007.jpg">
            <div style="width: 100%; padding-bottom:0.5em; padding-top:1em; margin-top: -1em; background-color:#145a75f6 ; color: #FFFFFF; text-align: center;">
                <div style="font-size: 34px;">安复每天   值得信赖</div>
            </div>
            <img width="100%" style="opacity: 1; margin-top: 1em;" src="image/Choose1.jpg">
            <div style="padding: 1em; text-align: left;">
                <b>洁净胶囊</b>
                <div class="fengeline1em"></div>
                <span>
                    行业首创洁净胶囊 FMT3.0 , 单疗程胶囊数量 14 粒，机构、用户使用更简易
                </span>
                <div class="fengeline1em"></div>
            </div>

            <div class="mob-line" style="height: 2px ; border-left: 380px solid #cfcfcf ; margin: -20px 5px 20px 2px"></div>
            <img width="100%" style="opacity: 1;" src="image/Choose2.jpg">
            <div style="padding: 1em; text-align: left;">
                <b>强技术迭代</b>
                <div class="fengeline1em"></div>
                <span>
                    技术储备充足 , 不断升级 , FMT3.5 筛菌技术+基因工程 , 保障合作产品技术领先优势
                </span>
                <div class="fengeline1em"></div>
            </div>

            <div class="mob-line" style="height: 2px ; border-left: 380px solid #cfcfcf ; margin: -20px 5px 20px 2px"></div>
            <img width="100%" style="opacity: 1;" src="image/Choose3.jpg">
            <div style="padding: 1em; text-align: left;">
                <b>全国产品供应商</b>
                <div class="fengeline1em"></div>
                <span>
                    为全国超过 50 家机构供应，严格控制质量，稳定产品，杜绝盲目扩张造成质量风险
                </span>
                <div class="fengeline1em"></div>
            </div>
            
            <div class="mob-line" style="height: 2px ; border-left: 380px solid #cfcfcf ; margin: -20px 5px 20px 2px"></div>
            <img width="100%" style="opacity: 1;" src="image/Choose4.jpg">
            <div style="padding: 1em; text-align: left;">
                <b>国家重点临床质量保证</b>
                <div class="fengeline1em"></div>
                <span>
                    包括武汉协和医院等，在北京、武汉、昆明、长沙、西安多家国家重点医院临床科研合作，
                    经过严格医疗审核认证
                </span>
                <div class="fengeline1em"></div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        }
    },
    data(){
        return {
            productshow : false , 
            mobproductshow: false ,
        }
    },
    mounted: function(){
        if(this.isMobile) {
            this.mobproductshow = true ;
        }else {
            this.productshow = true ; 
        }
    },
}
</script>

<style>

</style>