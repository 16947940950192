<template>
    <div>        
        <div class="technologyshow tumor-four" v-show="technologyshow">
            <table width="100%" style="margin: 2% 0%; " cellpadding="0" cellspacing="40">
            <tbody>
                <tr>
                    <td style="width: 50%;">
                        <img width="100%"  style="vertical-align: middle; opacity: 1; margin-left:30px ;" src="image/product.png">
                    </td>
                    <td style="padding-left: 10%;">
                        <font style="font-size: 48px; font-weight:bold;">安复每天</font>
                        <div class="fengeline2em"></div>
                        <font style="font-size: 24px;">安复每天 产品介绍</font>
                        <div class="fengeline2em"></div>
                    </td>
                </tr>
            </tbody>
            </table>
            <div style="width: 50%; margin: 0px 28%; text-align: center; line-height: 2em;">
                <font style="font-size: 36px;font-weight:bold; ">安复每天是一家专业的<br>菌群移植 FMT 的方案提供商</font>
                <div class="fengeline2em"></div>
                <font style="line-height: 1px ;">
                    公司开发FMT纯净胶囊适用于医疗、健康管理的方向，应用于包括精神疾病、肿瘤、消化、自身免疫、内分泌等治疗方案。
                    <br> 公司2021年开始与国内外多家知名医院、机构开展合作，为市场提供优质FMT产品。
                </font>
                <div class="fengeline2em"></div>
            </div>

            <div class="four-title">
                <h5>aFMT 实验室独家专利技术</h5>
                <p>Accur 精准配型 FMT 胶囊</p>
            </div>
            <main>
                <div class="four-num">
                    <div class="num-item">
                        <h5>Accur 真-精准配型 </h5>
                        <p>通过细菌代谢产物特定表达筛选，告别行业粗筛模式，特定症状有效率提升200%</p>
                    </div>
                    <div class="line"></div>
                    <div class="num-item">
                        <h5>独家超浓缩FMT3.0 </h5>
                        <p>胶囊实现洁净超浓缩，利用甾体技术，载菌量高于行业标准多倍</p>
                    </div>
                    <div class="line"></div>
                    <div class="num-item">
                        <h5>严格供体筛选</h5>
                        <p>超过80项医学严格诊断数据，供体长期随访制度，有效同时，杜绝感染风险</p>
                    </div>
                </div>
                <div class="fengeline2em"></div>
                <div class="four-target">
                    <h5 class="shsc--l"> MT01 胶囊</h5>
                    <p class="shsc--r">
                        标准洁净型——MT01系列是安复每天基于市场标准菌群移植推出的产品，具有普用性，主要针对轻微症状为主，调整身体状态，例如精神疲劳、肠胃炎症、肠道菌群紊乱等，适合普通人群的快速应用。
                    </p>
                    <img width="15%" style="opacity: 1; margin:0 1em;" src="image/product1.png">

                </div>
                <div class="four-target">
                    <h5 class="shsc--l">Accur 胶囊</h5>
                    <p class="shsc--r">
                        精准匹配型——Accur系列是安复每天针对于菌群移植适应症分类的旗舰产品，根据客户特定的适应症将FMT胶囊产品进行精准分类与匹配，高有效率，针对性强，主要针对肿瘤、免疫疾病、内分泌疾病、老年疾病等。适合适应症人群精准匹配的应用。
                    </p>
                    <img width="15%" height="90%" style="opacity: 1; margin:0 1em;" src="image/product2.png">
                </div>
                <div class="four-target">
                    <h5 class="shsc--l">Custom 胶囊</h5>
                    <p class="shsc--r">
                        超精定制型——Custom系列是安复每天针对高级客户需求单独定制的超级旗舰产品，根据客户情况进行FMT胶囊产品定制升级，提升相关菌群丰度及活性等级，从而高效解决客户症状，具有超高有效率，针对性强。主要针对基础代谢紊乱、肿瘤及放化疗康复、免疫疾病、内分泌疾病、老年疾病及与肠道菌群相关症状等。适合高端定制客户。
                    </p>
                    <img width="15%" height="90%" style="opacity: 1; margin:0.7em 1em 0 1em;" src="image/product3.png">
                </div>
            </main>
            <div class="fengeline2em"></div>
        </div>

        <div class="mobtechnologyshow mob-tumor-four" v-show="mobtechnologyshow">
            <img width="100%" src="image/product.png">
            <font style="font-size: 40px; padding-left: 10px; display:none;">关于安复每天</font>
            <div class="fengeline1em"></div>
            <font style="font-size: 20px; padding-left: 10px; display:none;">留住现在 安复每天</font>
            
            <div style="padding: 0px 2px;">
                <font style="display:block; text-align: center;  font-size: 28px; font-weight:bold; ">安复每天是一家专业的<br>菌群移植 FMT 的方案提供商</font>
                <br>
                <font style="font-size: 22px; ">
                    公司开发FMT纯净胶囊适用于医疗、健康管理的方向，应用于包括精神疾病、肿瘤、消化、自身免疫、内分泌等治疗方案。
                    <br> <br> 
                    公司2021年开始与国内外多家知名医院、机构开展合作，为市场提供优质FMT产品。
                </font>
            </div>

            <div class="mob-four-title">
                <h5>aFMT 实验室独家专利技术</h5>
                <p>Accur 精准配型 FMT 胶囊</p>
            </div>
            <main>
                <div class="mob-four-num">
                    <div class="mob-num-item">
                        <h5 style="text-align:center;">Accur 真-精准配型 </h5>
                        <p>通过细菌代谢产物特定筛选，告别行业粗筛模式，特定症状有效率提升200%</p>
                    </div>
                    <div class="mob-line"></div>
                    <div class="mob-num-item">
                        <h5 style="text-align:center;">独家浓缩 FMT 3.0 </h5>
                        <p>胶囊实现洁净超浓缩，利用甾体技术，载菌量高于行业标准多倍</p>
                    </div>
                    <div class="mob-line"></div>
                    <div class="mob-num-item">
                        <h5 style="text-align:center;">严格供体筛选</h5>
                        <p>超过80项医学严格诊断数据，供体长期随访制度，有效同时，杜绝感染风险</p>
                    </div>
                </div>
                <div class="mob-four-target">
                    <h5 class="mob-shsc--l"> MT01 胶囊</h5>
                    <p class="mob-shsc--r">
                        标准洁净型——MT01系列是安复每天基于市场标准菌群移植推出的产品，具有普用性，主要针对轻微症状为主，调整身体状态，例如精神疲劳、肠胃炎症、肠道菌群紊乱等，适合普通人群的快速应用。
                    </p>
                </div>
                <div class="mob-four-target">
                    <h5 class="mob-shsc--l">Accur 胶囊</h5>
                    <p class="mob-shsc--r">
                        精准匹配型——Accur系列是安复每天针对于菌群移植适应症分类的旗舰产品，根据客户特定的适应症将FMT胶囊产品进行精准分类与匹配，高有效率，针对性强，主要针对肿瘤、免疫疾病、内分泌疾病、老年疾病等。适合适应症人群精准匹配的应用。
                    </p>
                </div>
                <div class="mob-four-target">
                    <h5 class="mob-shsc--l">Custom 胶囊</h5>
                    <p class="mob-shsc--r">
                        超精定制型——Custom系列是安复每天针对高级客户需求单独定制的超级旗舰产品，根据客户情况进行FMT胶囊产品定制升级，提升相关菌群丰度及活性等级，从而高效解决客户症状，具有超高有效率，针对性强。主要针对基础代谢紊乱、肿瘤及放化疗康复、免疫疾病、内分泌疾病、老年疾病及与肠道菌群相关症状等。适合高端定制客户。
                    </p>
                </div>
            </main>
            <div class="fengeline2em"></div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        }
    },
    data(){
        return {
            technologyshow : false , 
            mobtechnologyshow: false ,
        }
    },
    mounted: function(){
        if(this.isMobile) {
            this.mobtechnologyshow = true ;
        }else {
            this.technologyshow = true ; 
        }
    },
}
</script>

<style scoped>
    .tumor-four {
        margin: -2% 0 0 0; 
        padding: 0 ; 
        border: 0;
    }
    .four-title{
        width:100% ; 
        height: 12.5em ;
        background-color: #145a75f6;
        color : #fff ; 
        text-align: center ;
    }
    .four-title h5{
        font-size: 1.6em; 
        padding-top: 3%;
        margin-bottom: -0.5%;
    }

    .tumor-four main{
        margin: 2% 6% 0 6%;
        width: 87.2% ;
    }

    .four-num {
        height: 140px ;
        background-color: #fff;
        transform: translateY(-6em);
        padding: 40px 90px;
        display: flex ;
    }
    .four-num .num-item {
        text-align: center ;
        width : 30em ;
        margin: -1.5em 1em;
    }
    .four-num h5{
        font-size: 1.5em ;
        color : #145a75f6 ;
    }

    .four-num p {
        margin-top: 2em;
        font-size: 1em;
        text-align: center ;

    }

    .four-num .line{
        height: 13em ;
        width: 0%;
        border-left: 1px solid #cfcfcf ;
    }

    .four-target{
        border-top: 1px solid #dcddde ;
        border-bottom: 1px solid #dcddde ;
        padding : 0.5em 1em 0 4em; 
        display: flex ;
    }
    .four-target .shsc--l {
        text-align: center ;
        width: 30%;
        font-size: 32px ;
        letter-spacing: 2px ;
        line-height: 36px ;
        margin-top: 2.5em ;
    }
    .four-target .shsc--r{
        width: 70%;
        font-size: 20px ;
        line-height: 36px;
        margin-left: 2em ;
    }

    .mob-tumor-four {
        margin: 10% 0 0 0; 
        padding: 0 ; 
        border: 0;
    }
    .mob-four-title{
        width:100% ; 
        height: 200px ;
        background-color: #145a75f6;
        color : #fff ; 
        text-align: center ;
        
    }
    .mob-four-title h5{
        font-size: 28px; 
        padding-top: 7%;
        margin-bottom: -1%;
    }

    .mob-tumor-four main{
        margin: 2% 2% 0 6%;
        width: 88% ;
    }

    .mob-four-num {
        height: 280px ;
        background-color: #fff;
        transform: translateY(-70px);
        padding: 10px 4px;
        margin : 0 -2% ;
        display: flex ;
    }
    .mob-four-num .mob-num-item {
        
        width : 130px ;
        margin: -20px 5px;
    }
    .mob-four-num h5{
        font-size: 21px ;
        color : #145a75f6 ;
    }

    .mob-four-num p {
        margin-top: -20px;
        font-size: 19px;
    }

    .mob-four-num .mob-line{
        height: 14em ;
        width: 0%;
        border-left: 1px solid #cfcfcf ;
    }

    .mob-four-target{
        border-top: 1px solid #dcddde ;
        padding : 0.5em 0.5em 0.5em ; 
        text-align: center;
        border-bottom: 1px solid #dcddde ;
    }
    .mob-four-target .mob-shsc--l {
        text-align: center ;
        font-size: 26px ;
        letter-spacing: 2px ;
        margin-top : 5%;
        line-height: 36px ;
       
    }
    .mob-four-target .mob-shsc--r{
        font-size: 20px ;
        line-height: 36px;
    }
</style>