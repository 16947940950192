<template>
  <div style="margin-bottom:5em;">
      <!-- 资讯快送 -->
        <div class="News" style="width: 70%; margin: 0px 15%; text-align: center; line-height: 2em;"  v-show="News" @mouseleave="leave" >
            <table width="100%" cellpadding="0" cellspacing="0" v-if="dataProject.length != 0">
                <tbody>
                    <tr>
                        <td style="width: 50%; text-align: left;"  >
                            <div>
                                <font style="font-size: 36px; font-weight:bold;">资讯快送</font>
                                <div class="fengeline1em"></div>
                                <font style="font-size: 22px;">
                                    FMT 技术在国际具有重要医疗影响力 <br> 有望在不久的未来 FMT 将实现部分药品替代
                                </font>
                            </div>
                            <div class="fengeline2em" style="border-bottom: 1px solid #EEEEEE; width: 80%;"></div>
                            <div class="ourjzgtlt" v-bind:class="[currentIndex == 0 ? ourjzgchooseClass : '']" @click="moreShow" @mouseenter="enter(0)" alt="t1" >
                                {{dataProject[0].title}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;">
								<path fill="#145a75f6" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path>
							</svg>
                            </div>
                            <div class="ourjzgtlt" v-bind:class="[currentIndex == 1 ? ourjzgchooseClass : '']" @click="moreShow" @mouseenter="enter(1)"  alt="t2">
                                {{dataProject[1].title}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;">
								<path fill="#145a75f6" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path>
							</svg>
                            </div>
                            <div class="ourjzgtlt" v-bind:class="[currentIndex == 2 ? ourjzgchooseClass : '']" @click="moreShow" @mouseenter="enter(2)" alt="t3">
                                {{dataProject[2].title}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;">
								<path fill="#145a75f6" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path>
							</svg>
                            </div>
                            <div class="ourjzgtlt" v-bind:class="[currentIndex == 3 ? ourjzgchooseClass : '']" @click="moreShow" @mouseenter="enter(3)" alt="t4">
                                {{dataProject[3].title}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;">
								<path fill="#145a75f6" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path>
							</svg>
                            </div>
                        </td>
                        <td  @mouseleave="leave" >
                            <div alt="t1" class="ourjiazhiguan"  v-show="seenImage&&currentIndex==0">
                                <img width="450vm" height="500vm" style="margin-left: 8%; opacity: 1;" :src="dataProject[0].newsPic" >
                                <div class="ourjzgtxt"  v-show="seenMore&&currentIndex==0">
                                    <b>{{dataProject[0].title}}</b>
                                    <br>
                                    <span style="font-size: 22px ;">{{dataProject[0].abstractWord}}</span>
                                    <!--
                                    <a href="" class="morebf" style="margin-top: 12%; margin-left: 2%; display: block ;">更多</a>
                                    -->
                                    <a @click="goContent(dataProject[0].id)" class="morebf" style="margin-top: 6%; margin-left: 2%; display: block ; cursor: pointer;">更多</a>
                                    
                                </div>
                            </div>
                            <div alt="t2" class="ourjiazhiguan"  v-show="seenImage&&currentIndex==1">
                                <img width="450vm" height="500vm" style="margin-left: 10%;opacity: 1;" :src="dataProject[1].newsPic" >
                                <div class="ourjzgtxt"  v-show="seenMore&&currentIndex==1">
                                    <b>{{dataProject[1].title}}</b><br>
                                    <span style="font-size: 22px ;">{{dataProject[1].abstractWord}}</span>
                                    <a @click="goContent(dataProject[1].id)" class="morebf" style="margin-top: 12%; margin-left: 2%; display: block ; cursor: pointer;">更多</a>
                                </div>
                            </div>
                            <div alt="t3" class="ourjiazhiguan" v-show="seenImage&&currentIndex==2">
                                <img width="450vm" height="500vm" style="margin-left: 10%;opacity: 1;" :src="dataProject[2].newsPic" >
                                <div class="ourjzgtxt"  v-show="seenMore&&currentIndex==2">
                                    <b>{{dataProject[2].title}}</b><br>
                                    <span style="font-size: 22px ;">{{dataProject[2].abstractWord}}</span>
                                    <a @click="goContent(dataProject[2].id)" class="morebf" style="margin-top: 12%; margin-left: 2%; display: block ; cursor: pointer;">更多</a>
                                </div>
                            </div>
                            <div alt="t4" class="ourjiazhiguan"  v-show="seenImage&&currentIndex==3">
                                <img width="450vm" height="500vm" style="margin-left: 10%;opacity: 1;" :src="dataProject[3].newsPic">
                                <div class="ourjzgtxt"  v-show="seenMore&&currentIndex==3">
                                    <b>{{dataProject[3].title}}</b><br>
                                    <span style="font-size: 22px ;">{{dataProject[3].abstractWord}}</span>
                                    <a @click="goContent(dataProject[3].id)" class="morebf" style="margin-top: 12%; margin-left: 2%; display: block ; cursor: pointer;">更多</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="mobNews" style="width: 100%; text-align: left; line-height: 1.5em; margin-top :10% ;" v-show="mobNews" v-if="dataProject.length != 0">
                <font style="font-size: 1.5em; font-weight:bold;">资讯快送</font>
                <div class="fengeline1em"></div>
                <div style="width: 100%; ">
                    <div style="margin-bottom: 0.5em ;">
                        <a href=""  
                            style="
                            margin-left: 0.2em; color: #333333; font-weight: 700;
                            display: block; margin-bottom: 0.6em;
                            border-bottom: 0.15em solid #333333;
                            width: 80%;
                            "
                        >{{dataProject[0].title}}</a>
                        <span>{{dataProject[1].abstractWord}}</span>
                    </div>
                    <img width="360vm" height="250vm" :src="dataProject[0].newsPic" >
                </div>
                <div class="fengeline1em"></div>
                <div>
                    <div class="">
                        <b>{{dataProject[1].title}}</b><br>
                        <span>{{dataProject[1].abstractWord}}</span>
                    </div>
                    <img width="360vm" height="250vm" :src="dataProject[1].newsPic" >
                </div>
                <div class="fengeline1em"></div>
                <div>
                    <div class="">
                        <b>{{dataProject[2].title}}</b><br>
                        <span>{{dataProject[2].abstractWord}}</span>
                    </div>
                    <img width="360vm" height="250vm" :src="dataProject[2].newsPic" >
                </div>
                <div class="fengeline1em"></div>
                <div>
                    <div class="">
                        <b>{{dataProject[3].title}}</b><br>
                        <span>{{dataProject[3].abstractWord}}</span>
                    </div>
                    <img width="360vm" height="250vm" :src="dataProject[3].newsPic" >
                </div>
                <div class="fengeline1em"></div>
            </div>

  </div>
</template>

<script>
import axios from 'axios' 

export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        },
        url :{
            type : String,
            default: "" ,
        }
    } ,
    data(){
        return {
            dataProject : [] ,
            currentIndex : 0 , 
            News : false , 
            mobNews : false , 
            seenImage : true ,
            seenMore : false ,
            ourjzgchooseClass : 'ourjzgchoose' ,
        }
    },
    methods: {
        enter:function(index) {
            this.seenImage = true;
            this.currentIndex = index ; 
           // console.log("seenenter = " + this.currentIndex) ; 
        },
        leave() {
           // console.log("seenleave = " + this.seen) ; 
            //this.seen = true;
            this.seenMore = false;
        } ,
        moreShow(){
            this.seenMore = true ;
           // console.log("seenMore " + this.seenMore) ;
        } , 
        goContent(id){
            this.$router.push({
                path: '/content',
                query : {
                    id : id ,
                }
            });
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        }
    } ,
    created:function(){
        let Mydata = new FormData() ; 
        var myUrl = this.url + "/api/news/list"; 
        axios (
            {
                method : "post" , 
                url : myUrl,
                data : Mydata ,
            }
        ).then(
            dataProject=>{
                this.dataProject = dataProject.data.data.rows  ; 
            }
        ) ; 
    },  
    mounted: function(){
        if(this.isMobile) {
           // console.log("shouji") ;
            this.mobNews = false ;
        }else {
            this.News = true ; 
        }
    },
}
</script>

<style>
        
    .ourjiazhiguan .ourjzgtxt {
        background-color: #FFFFFF;
        position: sticky;
        text-align: left;
        height: 10em;
        width: 18em;
        margin-top: -15em;
        font-size: 25px;
        padding: 1em 0em;
    }
    
    .ourjzgtlt {
        cursor: pointer;
        border-bottom: 2px solid #EEEEEE;
        width: 80%;
        padding: 0.7em 0em;
    }

    .ourjzgchoose {
        border-bottom: 2px solid #145a75f6;
        color: #145a75f6;
    }

    
</style>