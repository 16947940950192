<template>
    <div class="pubmenu">
        <div style="width:100%">
            <img class="logo"  @click="goHome" src="image/logo.png">
            <div class="rightmenu">
                <img @click="rightmenusearch" style="display:none;" class="rightmenusearch nomob" src="svg/search.svg">
                <font @click="rightmenusearch" style="display:none;" class="rightmenusearch nomob">搜索</font>
                <img @click="rightmenulist" class="rightmenushow" src="svg/caidan.svg">
                <font  @click="rightmenulist" class="rightmenushow">菜单</font>
            </div>
        </div>
        <!-- pc -->
        <div id="divpubmenulist" v-show="rightmenushow">
            <div style=" padding: 1em 0em 1em 1em; ">
                <img style="float:left; padding: 0em 1em 0em 1em; cursor: pointer;" width="130em"  @click="goHome" src="image/logo.png">
                <div @click="rightmenuclose" class="divpubmenuclose">
                    <img src="svg/close.svg" style="vertical-align: middle; padding-right: 0.5em; padding-bottom: 0.1em;">Close</div>
                </div>
                <table  style="float:left; " v-show="rightmenulistshow" id="tbpubmenulist" width="100%" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td style="width: 13em;" valign="top">
                                <ul class="ulpubmenulist">
                                    <li alt="Company" name="关于安复每天" class="wenzibold" @click="goHome">首页<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;"><path fill="#146772" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path></svg></li>
                                    <li alt="News" name="信息咨讯" class="wenzibold" @click="goNews">信息咨讯<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;"><path fill="#146772" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path></svg></li>
                                    <li alt="Products" name="公司产品" class="wenzibold" @click="goProducts">公司产品<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;"><path fill="#146772" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path></svg></li>
                                    <li alt="Contact" name="联系我们" class="wenzibold" @click="goContact">联系我们<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;"><path fill="#146772" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path></svg></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            <div v-show="rightmenusearchshow" id="tbpubmenusearch" width="100%">
                <div>
                    <input id="txtpubmenusearch" type="text" placeholder="搜索内容">
                    <button id="btnpubmenusearch">→</button>
                </div>
            </div>
        </div>

        <!-- mob -->
        <div id="divmobpubmenulist" v-show="mobrightmenushow">
            <div style="padding: 0em 0.1em 0.1em 0.1em; width:100%">
                <img style="float:left;  height: 2.6em; margin: 2px 0px 0px 2px;"  @click="goHome" src="image/logo.png">
                <div @click="rightmenuclose" class="divpubmenuclose" style=" float:right; padding-top: 0.8em; padding-right: 0.5em;">
                    <img src="svg/close.svg" style="vertical-align: middle;  padding-right: 0.5em; padding-bottom: 0.1em;">Close
                </div>
            </div>

            <ul class="mobulpubmenulist" style="float:left ;" v-show="mobulpubmenulist">
                <li alt="Company" name="关于安复每天" @click="goHome" >首页<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;"><path fill="#146772" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path></svg></li>
                <li alt="News" name="信息咨讯" @click="goNews">信息咨讯<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;"><path fill="#146772" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path></svg></li>
                <li alt="Products" name="公司产品" @click="goProducts">公司产品<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;"><path fill="#146772" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path></svg></li>
                <li alt="Contact" name="联系我们" @click="goContact">联系我们<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="float: right;padding-top: 0.5em;"><path fill="#146772" fill-rule="evenodd" d="M4,2.417,5.346,1,12,8,5.346,15,4,13.582,9.3,8Z"></path></svg></li>
            </ul>
        </div>
    </div>

</template>

<script>
export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        }
    } ,
    data(){
        return {
            rightmenushow:false ,
            rightmenulistshow:false ,

            rightmenusearchshow:false ,

            mobrightmenushow:false ,
            mobulpubmenulist:false , 
        }
    },
    methods:{
        rightmenulist(){
          //  console.log(this.isMobile) ; 
            if(this.isMobile == true){
                this.mobulpubmenulist = true ;
                this.mobrightmenushow = true ;
            }else {
                this.rightmenushow = true ;
                this.rightmenulistshow = true ;
            }
        },
        rightmenusearch(){
            this.rightmenushow = true ,
            this.rightmenusearchshow = true ;
        },
        rightmenuclose(){
            this.rightmenushow = false ,
            this.rightmenulistshow = false ;
            this.mobrightmenushow = false ;
            this.rightmenulistshow = false ;
        },
        goHome() {
            if (this.$route.path == '/') {
                this.rightmenuclose() ; 
            } else {
                this.$router.push("/");
            }
            document.body.scrollTop = 0 ;
            document.documentElement.scrollTop = 0 ;
        },
        goNews() {
            if(this.$route.path == '/news') {
                this.rightmenuclose() ;  
            }else {
                this.$router.push("/news") ;
            }
            document.body.scrollTop = 0 ;
            document.documentElement.scrollTop = 0 ;
        },
        goProducts(){
            if(this.$route.path == '/product') {
                this.rightmenuclose() ;  
            }else {
                this.$router.push("/product") ;
            }
            document.body.scrollTop = 0 ;
            document.documentElement.scrollTop = 0 ;
        },
        goContact(){
            if(this.$route.path == '/contact') {
                this.rightmenuclose() ;  
            }else {
                this.$router.push("/contact") ;
            }
            document.body.scrollTop = 0 ;
            document.documentElement.scrollTop = 0 ;
        },
    }, 
}
</script>

<style>

</style>