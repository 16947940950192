<template>
  <div id="app">
    <!-- 使用组件
    <Header></Header>
    -->
    <Header :isMobile="isMobile"></Header>
    <NewsMain :isMobile="isMobile" :url="url"></NewsMain>    
     
    <Cooperation :isMobile="isMobile"></Cooperation>
    
  </div>
</template>

<script> 
import Header from '../public/Header.vue'
import NewsMain from './newsMain.vue'
import Cooperation from '../public/Cooperation.vue'
import Index from '../index/index.vue'
export default {
  // 注册组件
  components: { 
    Header,
    NewsMain,
    Cooperation,
    Index,
  },
  data(){
    return {
      isMobile : false ,
      url : Index.components.httpUrl ,
    }
  },
  methods: {
    // 添加判断方法
    isMobileFunc() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  },
  created: function(){
    if(this.isMobileFunc()) {
      this.isMobile = true ;
    }else {
       this.isMobile = false ; 
    }
  },
  watch: {
      '$route' (to, from) {
          this.$router.go(0);
      }
  },
}
</script>

<style>
#app {
  border: 1px solid #ccc;
  padding: 10px ;
}

</style>
