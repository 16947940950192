<template>
    <div>
        <!-- pc -->
        <div class="main" style="width: 90%; margin-left: 5%;" v-show="mainshow">
            <!-- banner -->
            <table width="100%" cellpadding="0" cellspacing="40">
                <tbody>
                    <tr>
                        <td style="width: 50%;">
                            <img width="115%" style="vertical-align: middle;opacity: 1;" src="image/contact.png">
                        </td>
                        <td style="padding-left: 13%;">
                            <font style="font-size: 48px; font-weight:bold;">安复每天</font>
                            <div class="fengeline2em"></div>
                            <font style="font-size: 30px;">联系我们</font>
                            <div class="fengeline2em"></div>
                        </td>
                    </tr>
                </tbody>
            </table>

        
            <div class="fengeline2em"></div>
            <div class="fengeline2em"></div>
             
            <!-- 联系我们 -->
            <div id="mediamainbody" style="width: 80%; margin-left: 12%;">
                <div style="border-bottom: 1px solid #EEEEEE;">
                    <div style="font-size: 36px; font-weight:bold;">联系我们</div>
                    <div>
                        <div style="line-height: 2em; margin: 0.5em 0 0 1em;">
                            安复每天创始投入大量研发，开发的新一代FMT创新技术。
                            <br>公司开发FMT纯净胶囊适用于医疗、健康管理的方向，应用于包括精神疾病、肿瘤、消化、自身免疫、内分泌等治疗方案。
                            <br>
                            公司拥有医学发明专利多项，并采用国际领先制备方案，在菌群移植领域具有绝对技术领先优势。
                            <br>
                            安复每天与多家国家重点三甲医院、重点科研机构开展合作。
                        </div>
                        <p style="font-weight:bold;">如果您是医疗机构</p>
                        <div style="line-height: 2em; margin: 0.5em 0 0 1em;">
                            -我们为公立三甲医院提供临床试验产品支持合作；
                            <br>
                            -我们为公立及民营医院提供临床试验产品支持合作；
                            <br>
                            -我们为小型医疗机构提供产品支持合作。
                        </div>
                        <p style="font-weight:bold;"> 如果您是健康管理机构 </p>
                        <div style="line-height: 2em; margin: 0.5em 0 0 1em;">
                            -我们为健康管理机构提供技术培训；
                            <br>
                            -我们为健康管理机构提供应用产品支持。
                        </div>
                        <p style="font-weight:bold;"> 如果您是患者 </p>
                        <div style="line-height: 2em; margin: 0.5em 0 0 1em;">
                            -我们为您推荐所在地区的合作伙伴，进行相应的医疗或管理服务。
                        </div>
                    </div>
                    <div class="fengeline2em"></div>
                    
                </div>

                <div class="fengeline2em"></div>
            </div>
        </div>
        <!-- moblie -->
        <div class="mobmain" v-show="mobmainshow">
            <!-- banner -->
            <img width="100%" src="image/contact.png">
            <font style="font-size: 40px; padding-left: 10px; display:none;">安复每天</font>
            <div class="fengeline1em"></div>
            <font style="font-size: 20px; padding-left: 10px; display:none;">联系我们</font>
            
            <div style=" text-align: center; padding: 0px 2px;">
                <font style="font-size: 28px; font-weight:bold;  ">安复每天是一家专业的菌群移植 FMT 的方案提供商</font>
                <br>
                <font style="font-size: 22px; text-align: left; display:block;">
                    <br> 
                    安复每天创始投入大量研发，开发的新一代FMT创新技术。
                    公司开发FMT纯净胶囊适用于医疗、健康管理的方向，应用于包括精神疾病、肿瘤、消化、自身免疫、内分泌等治疗方案。
                    公司拥有医学发明专利多项，并采用国际领先制备方案，在菌群移植领域具有绝对技术领先优势。
                    <br>
                </font>
            </div>
            <div class="fengeline1em"></div>
            <div class="fengeline1em"></div>
            <div style="border-bottom: 1px solid #EEEEEE;">
                <div style="font-size: 36px; margin-bottom:2%; font-weight:bold;">联系我们</div>
                 
                <div>
                    <p style="font-weight:bold;">如果您是医疗机构</p>
                    <div style="line-height: 1.8em; margin: 0.5em 0 0 0.5em;">
                        -我们为公立三甲医院提供临床试验产品支持合作；
                        <br>
                        -我们为公立及民营医院提供临床试验产品支持合作；
                        <br>
                        -我们为小型医疗机构提供产品支持合作。
                    </div>
                    <p style="font-weight:bold;"> 如果您是健康管理机构 </p>
                    <div style="line-height: 1.8em; margin: 0.5em 0 0 0.5em;">
                        -我们为健康管理机构提供技术培训；
                        <br>
                        -我们为健康管理机构提供应用产品支持。
                    </div>
                    <p style="font-weight:bold;"> 如果您是患者 </p>
                    <div style="line-height: 1.8em; margin: 0.5em 0 0 0.5em;">
                        -我们为您推荐所在地区的合作伙伴，进行相应的医疗或管理服务。
                    </div>
                </div>
                <div class="fengeline2em"></div>
                
            </div>
        </div>
  </div>
</template>



<script>
export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        }
    },
    data(){
        return {
            mainshow : false , 
            mobmainshow: false ,
        }
    },
    mounted: function(){
        
        if(this.isMobile) {
            this.mobmainshow = true ;
        }else {
            this.mainshow = true ; 
        }
    },
}
</script>

<style>
	
</style>