<template>
  <div id="app">
    <!-- 使用组件
    <Header></Header>
    -->
    <Header :isMobile="isMobile"></Header>
    <Main :isMobile="isMobile"></Main>    
    <News :isMobile="isMobile" :url="url" v-if="isMobile == false"></News>
    <ResearchField :isMobile="isMobile"></ResearchField>
    <Product :isMobile="isMobile"></Product>
    <Technology :isMobile="isMobile"></Technology>
    
    <MobNews :isMobile="isMobile" :url="url" v-if="isMobile"></MobNews>
    <Cooperation :isMobile="isMobile"></Cooperation>
    
  </div>
</template>

 
<script> 
import Header from '../public/Header.vue'
import Main from './Main.vue'
import Product from './Products.vue'
import Cooperation from '../public/Cooperation.vue'
import News from './News.vue' 
import MobNews from './mobNews.vue'
import ResearchField from './researchField.vue'
import Technology from './Technology.vue'

const httpUrl = "https://www.affinemaintain.com"

export default {
  // 注册组件
  components: { 
    Header,
    Main,
    Product,
    Cooperation,
    News,
    MobNews,
    ResearchField ,
    Technology, 
    httpUrl,
  },
  data(){
    return {
      isMobile : false ,
      url : httpUrl,
    }
  },
  methods: {
    // 添加判断方法
    isMobileFunc() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  },
  created: function(){
    if(this.isMobileFunc()) {
      this.isMobile = true ;
    }else {
       this.isMobile = false ; 
    }
  },
  watch: {
      '$route' (to, from) {
          this.$router.go(0);
      }
  },
}
</script>

<style>
#app {
  border: 1px solid #ccc;
  padding: 10px ;
}

</style>
