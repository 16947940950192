<template>
 
    <div class="mobNews" style="width: 100%; text-align: left; line-height: 1.5em; margin-top :5% ;" v-show="mobNews" v-if="dataProject.length != 0">
        <font style="font-size: 1.5em; font-weight:bold;">资讯快送</font>
        <div class="fengeline1em"></div>
        <div style="width: 100%; " >
            <div style="margin-bottom: 0.5em ;">
                <a @click="goContent(dataProject[currentIndex].id)"
                    style="
                    margin-left: 0.2em; color: #333333; font-weight: 700;
                    display: block; margin-bottom: 0.6em;
                    border-bottom: 0.15em solid #333333;
                    width: 90%;
                    "
                >{{dataProject[currentIndex].title}}</a>
                
            </div>
            <div style="position: relative;">
                <img width="360vm" height="250vm" :src="dataProject[currentIndex].newsPic" >
                <svg class="arrow left" @click="left" width="30px" height="30.00px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#00857C" d="M481.233 904c8.189 0 16.379-3.124 22.628-9.372 12.496-12.497 12.496-32.759 0-45.256L166.488 512l337.373-337.373c12.496-12.497 12.496-32.758 0-45.255-12.498-12.497-32.758-12.497-45.256 0l-360 360c-12.496 12.497-12.496 32.758 0 45.255l360 360c6.249 6.249 14.439 9.373 22.628 9.373z"  /></svg>
                <svg class="arrow right" @click="right" width="30px" height="30.00px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#00857C" d="M557.179 904c-8.189 0-16.379-3.124-22.628-9.372-12.496-12.497-12.496-32.759 0-45.256L871.924 512 534.551 174.627c-12.496-12.497-12.496-32.758 0-45.255 12.498-12.497 32.758-12.497 45.256 0l360 360c12.496 12.497 12.496 32.758 0 45.255l-360 360c-6.249 6.249-14.439 9.373-22.628 9.373z"  /></svg>
            </div>
            <span>{{dataProject[currentIndex].abstractWord}}</span>
        </div>
        
        <div class="fengeline1em"></div>
    </div>
  
</template>

<script>
import axios from 'axios' 

export default {
    props:{
        isMobile :{
            type : Boolean , 
            default: false ,
        },
        url :{
            type : String,
            default: "" ,
        }
    } ,
    data(){
        return {
            dataProject : [] ,
            currentIndex : 0 , 
            mobNews : true ,
            time : 4000 , 
            timer : null , // 定时器
        }
    },
    methods: {
        start() {
            if(this.timer) {
                clearInterval(this.timer) ; 
            }
            this.timer =  setInterval(() => {
             // 修改显示 div 的索引
            if(++this.currentIndex >= 4){
                    this.currentIndex = 0 ;
                }
            } , this.time) ; 
        },
        left() {
            if(--this.currentIndex < 0) {
                this.currentIndex = 3 ;
            }
            this.start() ;
        },
        right() {
            if(++this.currentIndex >= 4) {
                this.currentIndex = 0 ; 
            }
            this.start() ;
        },
        goContent(id){
            this.$router.push({
                path: '/content',
                query : {
                    id : id ,
                }
            });
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        }
    } ,
    beforeDestory() {
        clearInterval(this.timer) ; 
        this.timer = null ;
    },
    created:function(){
        let Mydata = new FormData() ; 
        var myUrl = this.url + "/api/news/list"; 
        axios (
            {
                method : "post" , 
                url : myUrl,
                data : Mydata ,
            }
        ).then(
            dataProject=>{
                this.dataProject = dataProject.data.data.rows  ; 
            }
        ) ; 
    },  
    mounted: function(){
        this.start() ;
    },
}
</script>
 

<style>
    .inchiul li{width: 0.7em; border-bottom: 0.15em solid #d3d5d7; list-style: none; float: left; margin-left: 0.3em;}
    .inchiul .choli{border-bottom: 0.15em solid #00857d;}
    
    .btn-previous-meter{stroke: #d3d5d7; stroke-width: 1;}
    .arrowchoed{fill: #00857d;}
    .abtnfff {
        background-color: #00857d;
        color: #FFFFFF;
        padding: 0.3em 2em;
    }
    .arrow {
        background-color: rgba(0, 0, 0, 0.11);
        cursor: pointer;
    }
    .arrow:hover{
        background-color: rgba(0, 0, 0, 0.4);
    }
    .left{
        position: absolute ;
        top:45% ; 
        left: 10px ;
    }
    .right{
        position: absolute ;
        top:45% ; 
        right: 10px ;
    }
</style>
   